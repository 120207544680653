.contact {
  padding: 50px 20px;
  text-align: center; 
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contact h2 {
  font-size: 35px;
  font-weight: 800;
  background: linear-gradient(to bottom, #63D051, #438E36);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.contact-card {
  background-color: #1E1E1D;
  border-radius: 20px;
  padding: 60px 70px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-card form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-input, .contact-textarea {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
}

.contact-input:focus, .contact-textarea:focus {
  background-color: rgba(255, 255, 255, 0.4);
  outline: none;
}

.contact-input::placeholder, .contact-textarea::placeholder {
  color: #C5C5C5;
}

.contact-input {
  height: 30px;
}

.contact-textarea {
  height: 150px;
  resize: none;
}

.contact-button {
  background-color: #FFFFFF;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  color: #161513;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
  width: 150px;
  max-width: 150px;
  text-align: center;
}

.contact-button:hover {
  background-color: #f0f0f0; 
  color: black; 
}

.contact-button.success {
  background-color: #28a745;
  color: #FFFFFF;
}

.contact-button.error {
  background-color: #dc3545;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .contact-card {
    background-color: transparent;
    padding: 0;
    width: 100%;
  }

  .contact-input, .contact-textarea {
    width: 85%; 
  }
}
