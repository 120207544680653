.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 40px;
  background-color: #222222;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-logo {
  flex: 1;
  cursor: pointer;
}

.navbar-logo img {
  height: 45px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 35px;
  margin-right: 80px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  cursor: pointer;
  z-index: 1100;
}

.burger-bar {
  width: 25px;
  height: 3px;
  background-color: white;
  border-radius: 3px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #222222;
  z-index: 1000;
  overflow: hidden;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
}

.sidebar-container a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: space-between; 
    padding: 5px 20px;
    width: 100%;
  }

  .navbar-logo {
    flex: 1;
  }

  .navbar-links {
    display: none;
  }

  .burger-menu {
    display: flex;
    position: relative;
    right: 0;
    margin-right: 30px;
  }
}
