.footer {
  background-color: #232323;
  color: white;
  padding: 60px 20px;
  display: flex;
  justify-content: center;
}

.footer-content {
  width: 55%;
  max-width: 1000px;
  text-align: left;
}

.footer h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}

.footer p {
  margin: 10px 0;
  line-height: 1.6;
  text-align: left;
  width: 100%;
}

.email {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.email .icon {
  margin-right: 10px;
  font-size: 20px;
  color: white;
}

.email-link {
  color: white; 
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline; 
}

.social-icons {
  display: flex;
  gap: 20px;
  color: white;
}

.social-icons .icon {
  font-size: 20px;
  transition: transform 0.2s;
  color: white;
}

.social-icons .icon:hover {
  transform: scale(1.1);
  color: white;
}


@media (max-width: 768px) {
  .footer {
    padding: 35px 0;
    width: 100vw;
  }

  .footer-content {
    width: 85%;
  }

  .footer h2 {
    font-size: 20px;
  }

  .footer p {
    font-size: 16px;
  }

  .email .icon {
    font-size: 18px;
  }

  .social-icons .icon {
    font-size: 18px;
  }
}