.about {
  text-align: center;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  border-radius: 50%;
  height: 175px;
  width: 175px;
  object-fit: cover;
  padding-bottom: 10px;
}

h1 {
  font-size: 45px;
  margin: 0;
}

h3 {
  margin-top: 10px;
  margin-bottom: -5px;
  font-weight: 500;
}

.buttons .button {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: white;
  color: black;
  text-decoration: none;
  border-radius: 30px;
  border: 2px solid black;
  font-weight: 600; 
  cursor: pointer;
}

.buttons .button-outline {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.buttons .button:hover,
.buttons .button-outline:hover {
  background-color: #f0f0f0; 
  color: black; 
}

p {
  color: #C5C5C5;
  font-size: 15px;
  width: 55%;
  text-align: center;
  margin: 35px auto;
}

.experience-with {
  margin-top: 50px;
}

.experience-with h3 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  color: #C5C5C5;
}

.logos {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.logos img {
  height: 30px;
  width: auto;
}

@media (max-width: 768px) {
  p {
    width: 95%;
    font-size: 14px;
  }

  .about {
    padding: 10px 15px;
  }

  .profile-picture {
    height: 140px;
    width: 140px;
  }

  h1 {
    font-size: 40px;
  }

  h3 {
    font-size: 18px;
  }

  .experience-with {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .experience-with h3 {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .logos {
    position: relative;
    padding: 20px 0;
    cursor: grab;
    width: 55%;
    gap: 20px; 
  }

  .logos img {
    height: 16px; 
    width: auto; 
  }
}
