.projects {
  padding: 50px 20px;
  text-align: center; 
  position: relative;
}

.projects h2 {
  font-size: 35px;
  font-weight: 800;
  background: linear-gradient(to bottom, #FF8660, #D5491D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.carousel-container {
  overflow-x: hidden;
  padding: 20px 0;
  cursor: grab;
  position: relative;
}

.slides {
  display: flex;
  gap: 20px;
  will-change: transform;
}

.slides:hover {
  cursor: grab;
}

.slides:active {
  cursor: grabbing;
}

.project-card {
  flex: 0 0 auto;
  width: 23%;
}

@media (max-width: 768px) {

  .carousel-container {
    overflow-x: visible;
  }
  
  .slides {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .project-card {
    width: 85%; 
  }
}
