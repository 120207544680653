.experience-card {
  background-color: rgba(255, 255, 255, 0.2); 
  color: white;
  padding: 35px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.experience-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}

.company-logo {
  height: 85px;
  width: 85px;
  border-radius: 10px;
  margin-right: 20px;
}

.experience-card-header-content {
  display: flex;
  flex-direction: column;
}

.experience-card-header-date {
  white-space: nowrap;
}

.experience-card-body {
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  flex-grow: 1;
}

.experience-card-body ul {
  list-style-type: disc;
  padding-left: 20px;
}

.experience-card-body li {
  margin-bottom: 10px;
}

.experience-card-body li:last-child {
  margin-bottom: 0;
}

.experience-card-header h3 {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}

.experience-card-header p {
  margin: 5px 0 0;
  font-size: 16px;
  color: #C5C5C5;
  text-align: left;
}

.experience-card-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.visit-website-button {
  display: inline-flex;
  align-items: center;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.visit-website-button:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.visit-website-button svg {
  margin-left: 8px;
}

@media (max-width: 768px) {
  .experience-card {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    padding: 30px 25px;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
  }

  .company-logo {
    height: 65px;
    width: 65px;
    margin-right: 10px;
  }

  .experience-card-header {
    margin-bottom: 15px;
    gap: 10px;
  }

  .experience-card-header h3 {
    font-size: 20px;
    word-wrap: break-word; /* Ensure long text wraps properly */
  }

  .experience-card-header p {
    font-size: 13px;
  }

  .experience-card-footer {
    margin-top: 10px;
  }

  .experience-card-body {
    font-size: 14px;
    word-wrap: break-word; /* Ensure long text wraps properly */
  }

  .visit-website-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .visit-website-button svg {
    width: 14px;
    height: 14px;
  }
}

.experience-card-body ul {
  list-style-type: disc;
  padding-left: 20px;
  overflow-wrap: break-word; /* Ensure long text wraps properly */
}

.experience-card-body li {
  margin-bottom: 10px;
  overflow-wrap: break-word; /* Ensure long text wraps properly */
}