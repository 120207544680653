
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #161513;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.App {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  background-color: #161513;
}

section {
  padding: 30px 20px; 
}

#about {
  padding-top: 110px; 
}

@media (max-width: 768px) {
  #experience {
    padding: 30px 0;
  }
}